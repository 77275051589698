footer {
    display: grid;
    grid-template-columns: 400px auto auto auto 400px;
    align-items: center;
}

.grid-footer-logo {
    grid-column: 2;
    justify-self: end;
    padding-right: 10px;
}

.footer-logo-img {
    padding: 5px;
}

.grid-footer-info {
    grid-column: 3;
    border-right: darkgrey 2px solid;
    border-left: darkgrey 2px solid;
    /* height: 105px; */
}

.footer-text {
    text-align: center;
    padding-left: 12px;
    margin-bottom: 7px;
}

.grid-footer-icons {
    grid-column: 4;
    padding-left: 10px;
}

a {
    left: 0px;
}

.sm-icons {
    grid-column: 4;
    padding-left: 5px;
}

/* .footer-col-logo {
    width: 200px;
    right: 0px;
}

.footer-middle {
    position: absolute;
    left: 0px;
    border-right: 2px solid darkgrey;
} */