body {
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.message {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}
.message h2 {
    margin: 1rem;
}
.message .images {
    display: flex;
    justify-content: center;
}
#main-logo {
    width: 400px;
}

.browserLogo {
    max-width: 100px;
    max-height: 100px;
    margin-right: 2rem;
    margin-top: 1.3rem;
    margin-left: 1rem;
}

.footer {
    padding: 20px;
}

.footer-text {
    font-size: 14px;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.social-icons a {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
    color: #333;
}

i {
    font-size: 35px;
}

.AEC-name {
    font-weight: bold;
}

@media (max-width: 768px) {
    .message {
        font-size: 18px;
    }
}