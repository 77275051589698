.handshape-container {
  position: relative;
  display: inline-block;
}

.subhandshape-display-container {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 1;
}

.handshape-container:hover .subhandshape-display-container {
  display: block;
}