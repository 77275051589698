@media screen and (min-width: 768px) {
    .video-react {
        font-size: 16px;
    }
}

.video-react {
    font-family: Arial, Helvetica, sans-serif;
}

.video-react-menu .video-react-menu-content {
    font-family: Arial, Helvetica, sans-serif;
}

.video-react .video-react-control:focus:before, .video-react .video-react-control:hover:before, .video-react .video-react-control:focus {
    text-shadow: none;
}

.video-react .video-react-time-control {
    padding: 0;
}

.video-react .video-react-current-time {
    padding-right: 0.25em;
}

.video-react .video-react-duration {
    padding-left: 0.25em;
}

.no-controls .video-react-bezel {
    display: none !important;
}
.circleVideo .video-react-controls-enabled {
    border-radius: 50%;
}
.circleVideo .video-react-controls-enabled .video-react-video {
    border-radius: 50%;
}